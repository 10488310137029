export const rowsLowCh2: { [key: number]: number; } = {
	25: 1,
	26: 3,
	29: 5,
	30: 7,
	31: 9,
	24: 11,
	27: 12,
	28: 14,
};
