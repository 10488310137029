export const backgroundColors: string[] = [
	'white',
	'green',
	'blue',
	'cyan',
	'red',
	'yellow',
	'magenta',
	'black',
	'transparent',
];
