// Tables to look up row from PAC data

export const rowsLowCh1: { [key: number]: number; } = {
	17: 1,
	18: 3,
	21: 5,
	22: 7,
	23: 9,
	16: 11,
	19: 12,
	20: 14,
};
